@import "./variables";
@import "./largeScreens.scss";
@import "./midRangeScreens.scss";
@import "./mobilePhones.scss";
@import "./mobilePhonesPortrait.scss";

* {
  box-sizing: border-box;
}
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: "Normal";

  // scrollbar-color: yellow blue;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
body::-webkit-scrollbar {
  width: 0;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px $darkBlue;
}

body::-webkit-scrollbar-thumb {
  background-color: $blue;
  outline: 4px solid $blue;
}

@font-face {
  font-family: "Bold";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Bold.ttf") format("woff2");
}

@font-face {
  font-family: "Number";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/IRANSansMobileFaNum-Medium.ttf") format("woff2");
}

@font-face {
  font-family: "Light";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Light.ttf") format("woff2");
}
@font-face {
  font-family: "Medium";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/IRANSansMobileFaNum-Medium.ttf") format("woff2");
}
@font-face {
  font-family: "Normal";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Medium.ttf") format("woff2");
}
