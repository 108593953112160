//Colors
$black: #000;
$transparentBlack: rgba(0, 0, 0, 0.4);
$lightGray: #f8f8f8;
$gray: #797979;
$white: #fff;
$body: #faf9f9;
$safeArea: #faf9f9;
$statusBar: #ddd;
$orange: #ec4d11;
$yellow: #f6b517;
$red: #e74c3c;
$green: #27ae60;
$blue: #008dd5;
$lightBlue: #6dceff;

$darkBlue: #2c3e50;
$darkerBlue: rgba(45, 62, 80, 095);
$midGray: #4b4b4b;
$footer: #e5e5e5;
$placeholder: #999;

$mobileWidth: calc(100vh / 1.77);
