@media only screen and (min-width: 769px) and (max-width: 1280px) {
  .container {
    width: 100vw;
    align-items: center;
    flex-direction: column;
    display: flex;
  }
  .header {
    width: 100vw;
    height: 100vh;
    .headerLogo {
      width: 5vw !important;
      height: auto !important;
      position: absolute;
      top: 2vh;
      left: 10vw;
    }
    img {
      width: 100vw;
      height: 100vh;
    }
    .headerLinksDiv {
      position: absolute;
      top: 5vh;
      right: 10vw;
      width: 40vw;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      > span {
        color: white;
        font-family: "Normal";
        font-size: 1vmax;
        cursor: pointer;
        &:hover {
          color: $lightBlue;
        }
      }
    }
    .HeaderText {
      position: absolute;
      top: 20vh;
      right: 10vw;
      display: flex;
      flex-direction: column;
      height: 40vh;
      width: 25vw;
      align-items: flex-end;
      > img {
        width: 15vw;
        height: auto;
      }
      > span:first-child {
        color: white;
        text-align: right;
        direction: rtl;
        font-family: "Bold";
        font-size: 5vmax;
      }
      > span:nth-child(2) {
        color: white;
        text-align: right;
        direction: rtl;
        font-family: "Normal";
        margin-top: 5vh;
        font-size: 1.25vmax;
      }
    }
  }

  .downloadApp {
    width: 85vw;
    height: 64.5vmin;
    position: relative;
    border-bottom: solid 0.1vh #707070;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    > svg:first-child {
      width: auto;
      position: absolute;
      bottom: 0;
      left: 10vw;
      align-self: flex-end;
      height: 59.9vmin;
    }
    > div {
      width: 34.53vw;
      min-height: 50vmin;

      display: flex;
      position: absolute;
      bottom: 0;
      right: 10vw;

      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      > div {
        display: grid;
        grid-gap: 2vw;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto;
      }
      > span {
        width: 25.53vw;
        direction: rtl;
        font-size: 1vmax;
        margin-bottom: 3vh;

        color: #4b4b44;
      }
      .downloadAppHeader {
        color: $blue;
        font-family: "Bold";
        font-size: 1.2vmax;
      }
    }
    .downloadBtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 20.1vmax !important;
      height: 5.92vmin;
      background-color: #f0f0f0;
      margin-bottom: 2.31vmin;
      padding-left: 2.13vmax;
      padding-right: 2.13vmax;
      cursor: pointer;
      span {
        width: 15vw;
        margin-left: 2vw;
        color: #202020;
        direction: rtl;
        font-family: "Normal";
        font-size: 1.05vmax;
      }
      svg {
        width: 1.82vw;
        height: auto;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 7.29vw;
    padding-right: 7.29vw;
    padding-top: 4.62vh;
    min-height: 88.05vmin;
    > span:first-child {
      color: $blue;
      font-family: "Bold";
      margin-bottom: 5.55vh;
      font-size: 1.5vmax;
      width: 100%;
      text-align: center;
    }
    > span:nth-child(2) {
      color: $blue;
      font-family: "Normal";
      font-size: 1.35vmax;
      width: 85.41vw;
      margin-bottom: 7.5vmin;
      align-self: center;
      color: $midGray;
      height: 19.35vmin;
      text-align: center;
    }
    .descriptionColumnDiv {
      display: flex;
      flex-direction: row;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        svg {
          margin-bottom: 4.25vmin;
          width: 9.37vmax;
          height: auto;
        }
        span {
          text-align: center;
          margin-bottom: 3.25vh;
          font-family: "Normal";
          width: 20.83vw;
          font-size: 1.1vmax;
        }
        .descColHead {
          color: $blue;
          font-family: "Bold";
          font-size: 1.35vmax;
        }
      }
      div:first-child {
        margin-right: 11.14vw;
      }
      div:nth-child(2) {
        margin-right: 11.14vw;
      }
    }
  }

  .signUp {
    height: 41vmin;
    position: relative;
    width: 100vw;
    img {
      height: 41vmin;
      width: 100vw;
    }
    > div {
      background-color: #f0f0f0;
      width: 18.75vmax;
      height: 20vmin;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-end;
      padding-right: 3vw;
      top: 10.8vmin;
      right: 5vmax;
      border-bottom-left-radius: 3.25vmax;
      border-bottom-right-radius: 2vmax;
      border-top-left-radius: 3.25vmax;
      .signUpHead {
        color: #2e2e2e;
        font-size: 1.35vmax;
        font-family: "Bold";
      }
      .signUpBody {
        color: #2e2e2e;
        font-size: 1.1vmax;
        font-family: "Normal";
      }
      .signupBtn {
        color: $blue;
        border-radius: 1vmax;
        font-size: 1.1vmax;
        border: solid 0.2vh $blue;
        width: 7vmax;
        height: 3vmin;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row-reverse;
    align-self: flex-end;
    width: 100vw;
    height: 38.61vmin;
    background-color: $footer;
    padding-left: 5vmax;
    padding-right: 5vmax;
    > div:first-child {
      display: flex;
      width: 25.51vw !important;
      align-items: center;
      padding-top: 5.5vh;
      flex-direction: row-reverse;
      width: 5.46vw;
      height: auto;
      img {
        width: 5vw;
        height: auto;
        margin-left: 2vw;
      }
      span {
        text-align: center;
        color: #202020;
        font-family: "Normal";
        font-size: 0.97vmax;
      }
    }
    .footerInfo {
      width: 18.38vw;
      padding-top: 5.92vh;
      margin-right: 5.26vw;
      .ENamad {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 2vh;
        img {
          width: 7.81vw;
          height: auto;
        }
      }
      .footerCompanyInfo {
        display: flex;
        flex-direction: column;
        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.57vh;
          svg {
            width: 1.92vw !important;
            height: 1.92vw !important;
            margin-left: 1.5626vw;
          }
          span {
            width: 18.38vw;
            text-align: right;
            font-family: "Normal";
            font-size: 0.7vmax;
          }
          > span:nth-child(2) {
            padding-top: 1vh !important;
          }
        }
      }
    }
    .FooterLinksContainer {
      display: flex;
      flex-direction: row;

      .footerLinksDiv {
        width: 8.85vw;
        height: auto;
        display: flex;
        padding-top: 6.75vh;
        flex-direction: column;
        margin-right: 6.2vw;
        > span {
          text-align: right;
          cursor: pointer;
          margin-bottom: 1.48vh;
          font-size: 0.85vmax;
          font-family: "Normal";
        }
        > span:first-child {
          color: $darkBlue;
          cursor: default;
          font-size: 1.1vmax;
          text-align: right;
          font-family: "Bold";
          margin-bottom: 3.24vh;
        }
      }
    }
  }
  .mobileContainer {
    width: $mobileWidth;
    height: 100vh;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3.5vh;
    border: solid 1px $statusBar;
    position: relative;
    .HeaderImage {
      position: relative;
      width: $mobileWidth;
      width: 94%;
      height: 12vh;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 12vh;
      }
      span {
        z-index: 5;
        color: white;
        font-family: "Normal";
        font-size: 1.5vmax;
      }
    }
    .CodeTexts {
      width: 88.5%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      height: 25vh;
      > span:first-child {
        color: #2e2e2e;
        font-family: "Bold";
        font-size: 1.5vmax;
        text-align: right;
        direction: rtl;
      }
      > span:nth-child(2) {
        color: #2e2e2e;
        font-family: "Normal";
        font-size: 1.15vmax;
        text-align: center;
        direction: rtl;
      }
    }
    .BlueButton {
      width: 92%;
      display: flex;
      align-items: center;
      position: absolute;
      -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
      bottom: 2.7vh;
      justify-content: center;
      height: 6.6vh;
      border-radius: 4vmax;
      background-color: $blue;
      cursor: pointer;
      > span {
        cursor: pointer;
        color: white;
        font-family: "Normal";
        font-size: 1.5vmax;
      }
    }
    #myVideo {
      background-color: lightgray;
    }
    .BlueButtonExit {
      width: 92%;
      display: flex;
      align-items: center;
      position: absolute;
      -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
      bottom: 11vh;
      justify-content: center;
      height: 6.6vh;
      border-radius: 4vmax;
      background-color: $green;
      cursor: pointer;
      > span {
        cursor: pointer;
        color: white;
        font-family: "Normal";
        font-size: 1.5vmax;
      }
    }
    .BlueButtonExitHalf {
      width: 45%;
      display: flex;
      align-items: center;
      -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
      justify-content: center;
      height: 6.6vh;
      border-radius: 4vmax;
      background-color: $green;
      cursor: pointer;
      > span {
        cursor: pointer;
        color: white;
        font-family: "Normal";
        font-size: 1.5vmax;
      }
    }

    .CodeTextsRecive {
      width: 60.5%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      height: 25vh;
      span {
        color: #2e2e2e;
        font-family: "Normal";
        font-size: 1.5vmax;
        text-align: center;
        direction: rtl;
      }
    }

    .StepsText {
      width: 88.5% !important;
      display: flex;
      align-items: center;
      justify-content: space-evenly !important;
      flex-direction: column;
      height: 17vh !important;
      > span:first-child {
        color: #2e2e2e !important;
        font-family: "Bold" !important;
        font-size: 1.5vmax !important;
        text-align: right !important;
        direction: rtl !important;
      }
      > span:nth-child(2) {
        color: #2e2e2e !important;
        font-family: "Normal" !important;
        font-size: 1.25vmax !important;
        text-align: center !important;
        direction: rtl !important;
      }
    }

    > input[type="text"] {
      width: 88%;
      height: 4vh;
      border: none;
      outline: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      direction: rtl;
      text-align: center;
      font-size: 1.5vmax;
      border-bottom: solid $blue 0.2vh;
      &::placeholder {
        color: $statusBar;
        font-family: "Normal";
        font-size: 1.5vmax;
      }
    }
    > input[type="number"] {
      width: 88%;
      height: 4vh;
      border: none;
      outline: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      direction: rtl;
      text-align: center;
      font-size: 1.5vmax;
      border-bottom: solid $blue 0.2vh;
      &::placeholder {
        color: $statusBar;
        font-family: "Normal";
        font-size: 1.5vmax;
      }
    }
    .StepsDiv {
      width: 80%;
      row-gap: 2vmin;
      height: 40vh;
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
    }
    .StepItemDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > span {
        margin-top: 3vh;
      }
      .StepItem {
        width: 12.2vh;
        height: 12.2vh;
        border-radius: 50%;
        position: relative;
        -webkit-box-shadow: 0px 5px 7px 3px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 5px 7px 3px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 5px 7px 3px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        .StepItemCircle {
          width: 11vh;
          height: 11vh;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: solid $blue 0.2vh;
          > span {
            color: $blue;
            font-family: "Arial";
            font-weight: bold;
            font-size: 3.25vmax;
          }
        }
        > svg {
          height: 2.5vmin;
          width: auto;
          position: absolute;
          bottom: -2.2vmin;
        }
      }
    }
    .documents {
      height: 22vmin;
      width: 92%;
      position: relative;
      border: solid 0.3vmin $blue;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 5vmin;
      border-radius: 2vmax;
      > span:first-child {
        position: absolute;
        padding-left: 1vw;
        padding-right: 1vw;
        font-size: 1.5vmax;
        background-color: white;
        color: $blue;
        top: -1.5vmin;
      }
      > div {
        height: 13vh;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        span {
          font-size: 1.5vmax;
        }
      }
    }
    .StatusPage {
      width: inherit;
      height: inherit;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 10vw;
        height: auto;
        margin-bottom: 15vh;
      }
      > span:nth-child(2) {
        color: $blue;
        font-size: 1.5vw;
        text-align: center;
        margin-bottom: 5vh;
      }
      > span:nth-child(3) {
        color: black !important;
        font-size: 1vw;
        text-align: center;
        margin-bottom: 5vh;
      }
    }
    .MobileHeader {
      width: 100%;
      height: 8vh;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: space-between;
      padding-left: 3vw;
      padding-right: 3vw;
      top: 0;
      -webkit-box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.15);
      flex-direction: row-reverse;
      > span {
        color: #2e2e2e;
        font-size: 1vmax;
        text-align: center;
        cursor: pointer;
      }
      .selectedtext {
        color: $blue;
      }
    }
  }
  .codeInput {
    width: 70.8% !important;
    display: flex;
    position: relative;
    justify-content: space-between !important;
    flex-direction: row !important;
    input[type="number"] {
      width: 17%;
      height: 4vh;
      border: none;
      outline: none;
      display: flex;
      color: #2e2e2e !important;
      flex-direction: row;
      font-family: "Normal" !important;
      font-size: 1.25vmax !important;
      align-items: center;
      justify-content: center;
      direction: rtl;
      text-align: center;
      border-bottom: solid $blue 0.2vh;
      &::placeholder {
        color: $statusBar !important;
        font-family: "Normal" !important;
        font-size: 1vw !important;
      }
    }
  }
  .InfoForm {
    margin-top: 10vmin;
    width: 87.5%;
    display: flex;

    flex-direction: column;
    > span {
      color: #676767;
      direction: rtl;
      text-align: right;
      width: 98%;
      margin-bottom: 1.5vmin;
    }
    label {
      display: inline-block;
      position: absolute;
      right: 2.5vw;
      direction: rtl;
      text-align: right;
      top: 2.25vh;
      font-size: 1.25vmax;
      transition: all 150ms ease-in;
      color: #676767;
      &.field-active {
        transform: translateY(-3.25vh);
        right: 1.5vw;
        background-color: white;
        color: $blue;
        padding-left: 0.25vw;
        padding-right: 0.25vw;
        font-size: 1.25vmax;
      }
      &.field-active-notFocus {
        transform: translateY(-3.25vh);
        right: 1.5vw;
        background-color: white;
        color: $green;
        padding-left: 0.25vw;
        padding-right: 0.25vw;
        font-size: 1.25vmax;
      }
    }
    .floating-label {
      width: 100%;
      height: 7vh;
      text-align: right;
      direction: rtl;
      font-family: "Normal";
      font-size: 1.25vmax;
      color: #2e2e2e;
      padding-right: 2vw;
      outline: none;
      border: solid $statusBar 0.15vh;
      border-radius: 5px;
    }
    .border-active {
      border-color: $blue;
    }
    .border-Notactive {
      border-color: $green;
    }
    .border-error {
      border-color: $red;
    }
    .field-group {
      position: relative;
      margin-bottom: 3vh;
      width: 100%;

      height: 7vh;
    }
    .field-group-half {
      position: relative;
      margin-bottom: 3vh;
      width: 49%;
      height: 7vh;
    }
    .field-group-third {
      position: relative;
      margin-bottom: 3vh;
      width: 32%;
      height: 7vh;
    }
    .BDayDIV {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
    }
  }
  .TermsTexts {
    height: 70vh;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
    > span:first-child {
      color: #2e2e2e;
      font-size: 2vmax;
      text-align: center;
      margin-bottom: 3vh;
    }
    .HiddenScroll {
      color: #2e2e2e;
      font-size: 1.75vmax;
      width: 85%;
      white-space: pre-wrap;
      direction: rtl;
      height: 60vh;
      overflow-y: scroll;
      text-align: center;
      -ms-overflow-style: none;
      scrollbar-width: none !important;
      margin-bottom: 3vh;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
  }
  .VideoRecordingDiv {
    width: inherit;
    z-index: 120;
    overflow: visible;
    height: 20vh !important;
    position: absolute;
    bottom: 35vh;
  }
  .hCBOlN {
    bottom: 60px !important;
  }
  .UploadContain {
    margin-top: 10vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .uploadCard {
    width: 87.5%;
    height: 12.7vh;
    display: flex;
    margin-bottom: 2vh;
    position: relative;
    flex-direction: row;
    border-radius: 0.5vw;
    align-items: center;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    border: solid 0.06vh #707070;
    .ImageContainer {
      width: 11.14vh !important;
      cursor: pointer;
      height: 11.14vh !important;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5vw;
      border: 0.1vh solid #b9b9b9;
      background-color: #f3f3f3;
      padding: 0.025vh;
      margin-right: 0.5vw;
      img {
        width: 11.14vh !important;
        height: 11.14vh !important;
        overflow: hidden;
        border-radius: 0.5vw;
        object-fit: cover !important;
      }
      .twoImageText {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        span {
          margin-top: 1vh;
          color: #808080;
          font-size: 1vw;
        }
      }
    }
    .uploadText {
      width: 14vmax;
      height: 10vh;
      position: absolute;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      right: 1vmax;
      span {
        text-align: right;
        direction: rtl;
        width: 10vmax !important;
        margin-right: 1vmax;
        font-size: 1.25vmax;
      }
      .TickDiv {
        width: 2.5vmax;
        height: 2.5vmax;
        border-radius: 50%;
        background-color: #f3f3f3;
      }
      .TickDivSelected {
        background-color: #4aa84e;
      }
    }
  }
  .RecordTexts {
    width: 100%;
    height: 70vh;
    margin-top: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    > span {
      text-align: center;
      width: 85%;
    }
    .Recordheader {
      font-size: 2.25vmax;
      color: #2e2e2e;
      margin-bottom: 3vh;
    }
    .Recordinfo {
      font-size: 1.5vmax;
      color: #2e2e2e;
      margin-bottom: 2vh;
    }
    .Recordtext {
      font-size: 1.25vmax;
      color: black;
      margin-bottom: 2vh;
    }
  }
  .Recording {
    margin-top: 10vh !important;
  }
}
